const bo_routes = [
  {
    path: '',
    redirect: { name: 'BoLogin' },
  },
  {
    path: 'login',
    name: 'BoLogin',
    component: () => import('@/views/backend/BoLogin.vue'),
    meta: {
      redirectIfLoggedIn: true,
      layout: 'empty',
    }
  },
  {
    path: 'dashboard/:pageSlug?',
    name: 'BoDashboard',
    component: () => import('@/views/backend/BoDashboard.vue'),
    meta: {
      menu: 'BoDashboard',
      pageTitle: 'Dashboard',
      objName: 'Dashboard',
    },
  },
  {
    path: 'account-setting',
    name: 'BoAccountSetting',
    component: () => import('@/views/backend/BoAccountSetting.vue'),
    meta: {
      menu: 'BoAccount',
      pageTitle: 'Account Setting',
      objName: 'Account',      
    },
  },
  {
    path: 'user/:pageSlug?',
    name: 'Users',
    component: () => import('@/views/backend/BoUser/List.vue'),
    meta: {
      menu: "Users",
      pageTitle: 'Users',
      hasCta: 'true',
      objName: 'User'
    },
  },
  {
    path: 'user-level/:pageSlug?',
    name: 'BoUserLevel',
    component: () => import('@/views/backend/BoUserLevel/List.vue'),
    meta: {
      menu: 'BoUserLevel',
      pageTitle: 'User Level',
      hasCta: 'true',
      objName: 'User Level'
    },
  },
  {
    path: 'news-list/:pageSlug?',
    name: 'BoBlog',
    component: () => import('@/views/backend/BoBlog/List.vue'),
    meta: {
      menu:'BoBlog',
      pageTitle: 'News List',
      hasCta: true,
      objName: 'News'
    }
  },
  {
    path: 'event-list/:pageSlug?',
    name: 'BoEvent',
    component: () => import('@/views/backend/BoEvent/List.vue'),
    meta: {
      menu:'BoEvent',
      pageTitle: 'Event List',
      hasCta: true,
      objName: 'Event'
    }
  },
  {
    path: 'csr-list/:pageSlug?',
    name: 'BoCSR',
    component: () => import('@/views/backend/BoCSR/List.vue'),
    meta: {
      menu:'BoCSR',
      pageTitle: 'CSR List',
      hasCta: true,
      objName: 'CSR'
    }
  },
  {
    path: 'csr-category/:pageSlug?',
    name: 'BoCSRCategory',
    component: () => import('@/views/backend/BoCSRCategory/List.vue'),
    meta: {
      menu:'BoCSRCategory',
      pageTitle: 'CSR Category',
      hasCta: true,
      objName: 'Category'
    }
  },
  {
    path: 'client/:pageSlug?',
    name: 'BoClients',
    component: () => import('@/views/backend/BoClients/List.vue'),
    meta: {
      menu:'BoClients',
      pageTitle: 'Client List',
      hasCta: true,
      objName: 'Clients'
    }
  },
  {
    path: 'blog-category/:pageSlug?',
    name: 'BoBlogCategory',
    component: () => import('@/views/backend/BoBlogCategory/List.vue'),
    meta: {
      menu: 'BoBlogCategory',
      pageTitle: 'Blog Category',
      hasCta: true,
      objName: 'Blog Category'
    }
  },
  {
    path: 'contact/:pageSlug?',
    name: 'BoContact',
    component: () => import( /* webpackChunkName: "about" */ '@/views/backend/BoContact/BoContact.vue'),
    meta: {
      pageTitle: 'Contact',
      menu: 'BoContact',
      objName: 'Contact',
    }
  }, {
    path: 'contact-page',
    name: 'BoContactPage',
    component: () => import( /* webpackChunkName: "about" */ '@/views/backend/BoContact/BoContactPage.vue'),
    meta: {
      pageTitle: 'Contact Page',
      menu: 'BoContactPage',
      objName: 'Contact Page',
    }
  },
  {
    path: 'home',
    name: 'BoHome',
    component: () => import('@/views/backend/BoHome/BoHome.vue'),
    meta: {
      menu: 'BoHome',
      pageTitle: 'Home',
      objName: 'Home'
    }
  },
  {
    path: 'about-us',
    name: 'BoAboutUs',
    component: () => import('@/views/backend/BoAboutUs/BoAboutUs.vue'),
    meta: {
      menu: 'BoAboutUs',
      pageTitle: 'About Us',
      objName: 'About Us'
    }
  },
  {
    path: 'anak-perusahaan',
    name: 'BoAnakPerusahaan',
    component: () => import('@/views/backend/BoAnakPerusahaan/BoAnakPerusahaan.vue'),
    meta: {
      menu: 'BoAnakPerusahaan',
      pageTitle: 'Anak Perusahaan',
      objName: 'Anak Perusahaan'
    }
  },
  {
    path: 'products/:pageSlug?',
    name: 'BoProducts',
    component: () => import('@/views/backend/BoProducts/List.vue'),
    meta: {
      pageTitle: 'Products',
      hasCta: true,
      menu: 'BoProducts',
      objName: 'Product',
    }
  },
  {
    path: 'product-category/:pageSlug?',
    name: 'BoProductCategory',
    component: () => import('@/views/backend/BoProductCategory/List.vue'),
    meta: {
      pageTitle: 'Product Category',
      hasCta: true,
      menu: 'BoProductCategory',
      objName: 'Category',
    }
  },
  {
    path: 'line-production/:pageSlug?',
    name: 'BoLineProduction',
    component: () => import('@/views/backend/BoLineProduction/List.vue'),
    meta: {
      menu: 'BoLineProduction',
      pageTitle: 'Line Production',
      hasBreadCrumb : false,
      objName: 'Line Production',
    }
  },
  {
    path: 'line-production-list/:pageSlug?',
    name: 'BoLineProductionList',
    component: () => import('@/views/backend/BoLineProductionList/List.vue'),
    meta: {
      menu: 'BoLineProductionList',
      pageTitle: 'Line Production',
      hasCta: true,
      objName: 'Line Production',
    }
  },
  {
    path: 'career/:pageSlug?',
    name: 'BoCareer',
    component: () => import('@/views/backend/BoCareer/BoCareer.vue'),
    meta: {
      menu: 'BoCareer',
      pageTitle: 'Career',
      hasBreadCrumb : false,
      objName: 'Vacancy',
    }
  },
  {
    path: 'job-applicants/:pageSlug?',
    name: 'BoApplicants',
    component: () => import('@/views/backend/BoCareer/BoApplicants.vue'),
    meta: {
      menu: 'BoApplicants',
      hasCta: false,      
      pageTitle: 'Job Applicants',
      objName: 'Job Applicants',
    }
  },
  {
    path: 'legal/terms-and-conditions',
    name: 'BoTermCondition',
    component: () => import('@/views/backend/BoLegal/Terms.vue'),
    meta: {
      pageTitle: 'Legal',
      menu: 'BoTermCondition',
      hasBreadCrumb: false,
      objName: 'Term and Conditions',
    }
  },
  {
    path: 'legal/privacy-policy',
    name: 'BoPrivacyPolice',
    component: () => import('@/views/backend/BoLegal/Privacy.vue'),
    meta: {
      pageTitle: 'Legal',
      menu: 'BoPrivacyPolice',
      hasBreadCrumb: false,
      objName: 'Privacy Police',
    }
  },
  {
    path: 'web-settings',
    name: 'BoWebSettings',
    component: () => import('@/views/backend/BoWebSettings/BoWebSettings.vue'),
    meta: {
      pageTitle: 'Web Settings',
      menu: 'WebSettings',
      objName: 'Web Settings'
    }
  },
]
export default bo_routes